const layout = [
    {
        id: "sidebar",
        width: 250,
        height: "100vh"
    },
    {
        id: "main"
    },
]

const vertical_layout = [
    {
        id: "ribbon",
    },
    {
        id: "main",
    },
]

export default {layout, vertical_layout} 