const classif_data = [
    {
        name: "Общероссийский классификатор продукции по видам экономической деятельности",
        count: 19987,
        created_at: "2024-01-01",
        tab: "КЛСФ 1",
        data: [
            {
                id: "01",
                kod: "01",
                name: "01 Продукция и услуги сельского хозяйства и охоты"
            },
            {
                id: "02",
                kod: "02",
                name: "02 Продукция лесоводства, лесозаготовок и связанные с этим услуги"
            },
            {
                id: "01.1",
                kod: "01.1",
                name: "01.1 Культуры однолетние",
                parent: "01"
            },
            {
                id: "01.11",
                kod: "01.11",
                name: "01.11 Культуры зерновые (кроме риса), зернобобовые, семена масличных культур",
                parent: "01"
            },
            {
                id: "01.12",
                kod: "01.12",
                name: "01.12 Рис нешелушеный",
                parent: "01"
            },
            {
                id: "01.11.1",
                kod: "01.11.1",
                name: "01.11.1 Пшеница",
                parent: "01.11"
            },
            {
                id: "01.11.11",
                kod: "01.11.11",
                name: "01.11.11 Пшеница твердая",
                parent: "01.11"
            },
            {
                id: "01.11.12",
                kod: "01.11.12",
                name: "01.11.12 Пшеница, кроме твердой пшеницы",
                parent: "01.11"
            },
            {
                id: "02.1",
                kod: "02.1",
                name: "02.1 Продукция лесоводства, услуги лесопитомников",
                parent: "02"
            },
            {
                id: "02.10",
                kod: "02.10",
                name: "02.10 Продукция лесоводства, услуги лесопитомников",
                parent: "02"
            },
        ],
        content: [
            {
                value: "01 Продукция и услуги сельского хозяйства и охоты",
                id: "01",
                items: [
                    {
                        value: "01.1 Культуры однолетние",
                        id: "01.1",
                    },
                    {
                        value: "01.11 Культуры зерновые (кроме риса), зернобобовые, семена масличных культур",
                        id: "01.11",
                        items: [
                            {
                                value: "01.11.1 Пшеница",
                                id: "01.11.1",
                            },
                            {
                                value: "01.11.11 Пшеница твердая",
                                id: "01.11.11",
                            },
                            {
                                value: "01.11.12 Пшеница, кроме твердой пшеницы",
                                id: "01.11.12",
                            },
                        ]
                    },
                    {
                        value: "01.12 Рис нешелушеный",
                        id: "01.12",
                    },
                ]
            },
            {
                value: "02 Продукция лесоводства, лесозаготовок и связанные с этим услуги",
                id: "02",
                items: [
                    {
                        value: "02.1 Продукция лесоводства, услуги лесопитомников",
                        id: "02.1",
                    },
                    {
                        value: "02.10 Продукция лесоводства, услуги лесопитомников",
                        id: "02.10",
                    },
                ]
            }
        ]
    },
    {
        name: "Общероссийский классификатор видов экономической деятельности",
        count: 2904,
        created_at: "2024-01-01",
        tab: "КЛСФ 2",
        data: [
            {
                id: "01",
                kod: "01",
                name: "01 Растениеводство и животноводство, охота и предоставление соответствующих услуг в этих областях"
            },
            {
                id: "02",
                kod: "02",
                name: "02 Лесоводство и лесозаготовки"
            },
            {
                id: "01.1",
                kod: "01.1",
                name: "01.1 Выращивание однолетних культур",
                parent: "01"
            },
            {
                id: "01.11",
                kod: "01.11",
                name: "01.11 Выращивание зерновых (кроме риса), зернобобовых культур и семян масличных культур",
                parent: "01"
            },
            {
                id: "01.12",
                kod: "01.12",
                name: "01.12 Рис нешелушеный",
                parent: "01"
            },
            {
                id: "01.11.1",
                kod: "01.11.1",
                name: "01.11.1 Выращивание зерновых культур",
                parent: "01.11"
            },
            {
                id: "01.11.11",
                kod: "01.11.11",
                name: "01.11.11 Выращивание пшеницы",
                parent: "01.11"
            },
            {
                id: "01.11.12",
                kod: "01.11.12",
                name: "01.11.12 Выращивание ячменя",
                parent: "01.11"
            },
            {
                id: "02.1",
                kod: "02.1",
                name: "02.1 Лесоводство и прочая лесохозяйственная деятельность",
                parent: "02"
            },
            {
                id: "02.10",
                kod: "02.10",
                name: "02.10 Лесоводство и прочая лесохозяйственная деятельность",
                parent: "02"
            },
        ],
        content: [
            {
                value: "01 Растениеводство и животноводство, охота и предоставление соответствующих услуг в этих областях",
                id: "01",
                items: [
                    {
                        value: "01.1 Выращивание однолетних культур",
                        id: "01.1",
                    },
                    {
                        value: "01.11 Выращивание зерновых (кроме риса), зернобобовых культур и семян масличных культур",
                        id: "01.11",
                        items: [
                            {
                                value: "01.11.1 Выращивание зерновых культур",
                                id: "01.11.1",
                            },
                            {
                                value: "01.11.11 Выращивание пшеницы",
                                id: "01.11.11",
                            },
                            {
                                value: "01.11.12 Выращивание ячменя",
                                id: "01.11.12",
                            },
                        ]
                    },
                    {
                        value: "01.12 Выращивание риса",
                        id: "01.12",
                    },
                ]
            },
            {
                value: "02 Лесоводство и лесозаготовки",
                id: "02",
                items: [
                    {
                        value: "02.1 Лесоводство и прочая лесохозяйственная деятельность",
                        id: "02.1",
                    },
                    {
                        value: "02.10 Лесоводство и прочая лесохозяйственная деятельность",
                        id: "02.10",
                    },
                ]
            }
        ]
    },
]




export default classif_data